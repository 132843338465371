import withRoot from "../../utils/withRoot";
import React from "react";
import { Link, graphql, withPrefix } from "gatsby";
import Typography from "@material-ui/core/Typography";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

const Resources = props => {
  return (
    <Page title="当前位置：首页>资源下载">
      <SEO title="资源下载 英语启蒙 思维导图" />
     <br></br>
      <Typography variant="h4">思维导图</Typography>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemLink href="http://files.kids-abc.cn/小鱼儿亲子英语陪伴计划第二章：穿衣服.pdf">
          <ListItemText primary="小鱼儿亲子英语陪伴计划第二章：穿衣服.pdf" />
        </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemLink href="http://files.kids-abc.cn/小鱼儿亲子英语陪伴计划第三章：穿鞋.pdf">
          <ListItemText primary="小鱼儿亲子英语陪伴计划第三章：穿鞋.pdf" />
        </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemLink href="http://files.kids-abc.cn/小鱼儿亲子英语陪伴计划第四章：洗脸.pdf">
          <ListItemText primary="小鱼儿亲子英语陪伴计划第四章：洗脸.pdf" />
        </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemLink href="http://files.kids-abc.cn/小鱼儿亲子英语陪伴计划第五章：洗手.pdf">
          <ListItemText primary="小鱼儿亲子英语陪伴计划第五章：洗手.pdf" />
        </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemLink href="http://files.kids-abc.cn/小鱼儿亲子英语陪伴计划第六章：刷牙.pdf">
          <ListItemText primary="小鱼儿亲子英语陪伴计划第六章：刷牙.pdf" />
        </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemLink href="http://files.kids-abc.cn/小鱼儿亲子英语陪伴计划第二章：穿衣服.pdf">
          <ListItemText primary="小鱼儿亲子英语陪伴计划第二章：穿衣服.pdf" />
        </ListItemLink>
        </ListItem>
      </List>
    
    </Page>
  );
};

export default withRoot(Resources);
